import React, { useEffect, useRef, useState } from 'react'
import Layout from '../components/Layout.js'
import Button from '../components/Button.js'

const About = () => {
  const [activeSection, setActiveSection] = useState(null);
  const podcastIndexRef = useRef(null);
  const openPodcastingRef = useRef(null);

  const sections = [
    { name: 'podcastIndex', ref: podcastIndexRef },
    { name: 'openPodcasting', ref: openPodcastingRef },
  ];

  const handleScroll = () => {
    const currentSection = sections.find(section => {
      const element = section.ref.current;
      if (element) {
        const { offsetTop } = element;
        const screenPosition = window.scrollY + window.innerHeight / 2;
        return screenPosition > offsetTop && element.getBoundingClientRect().bottom >= 50;
      }
      return false;
    });

    setActiveSection(currentSection ? currentSection.name : null);
  };

  const handleClick = (jumpToSectionRef) =>{
    jumpToSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    const currentSection = sections.find(section =>{
      return section.ref === jumpToSectionRef;
    })
    setActiveSection(currentSection.name)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return (
      <Layout title="About Boostz" marginBottomClass={"mb-0"}>
          <section className="font-mono xl:px-12">
              <h1 className="text-white mx-auto mt-[50px] mb-[50px] text-[50px] tracking-wide max-md:text-[30px] max-md:mt-[30px] max-md:mb-[50px] md:max-lg:text-[30px]">
                  <span className="text-gradient from-purple-600 to-pink-600 pl-1.5 pr-1.5">
                  Open Podcasting via
                  </span>
                  <br />
                  <span className="text-gradient from-purple-600 to-pink-600 pl-1.5 pr-1.5">
                   Podcasting 2.0
                  </span>
              </h1>
              <div className="flex flex-col border-t border-gray-700 md:flex-row">
                  <div className=" hidden w-0 lg:h-full lg:sticky lg:top-[90px] lg:w-1/4 lg:justify-left lg:items-center lg:flex lg:flex-1 lg:text-2xl lg:mt-4">
                      <ul>
                          <li
                              className={`${
                                  activeSection === "podcastIndex"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(podcastIndexRef)}
                          >
                              Podcast Index
                          </li>
                          <li
                              className={`${
                                  activeSection === "openPodcasting"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(openPodcastingRef)}
                          >
                              Open Podcasting
                          </li>
                      </ul>
                  </div>
                  <div className="w-full pl-1.5 lg:border-l lg:border-gray-700 lg:w-3/4 lg:pl-10">
                      <div id="whyBitcoinLightning" ref={podcastIndexRef} className="pt-16">
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                              Podcast Index
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                          STUB
</p>

                      </div>
                      <div
                          id="openPodcasting"
                          ref={openPodcastingRef}
                          className="pt-16"
                      >
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                          Open Podcasting
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                              STUB
                          </p>
                      </div>
                      <div className="pt-16 pb-24">
                          <Button
                              text="Contact Us"
                              link="/contact"
                              variant="gradient"
                              shadow={true}
                          />
                      </div>
                  </div>
              </div>
          </section>
      </Layout>
  );
}

export default About