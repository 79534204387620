import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module'

import Home from './pages/Home.js';
import NotFoundPage from './pages/NotFoundPage.js';
import About from './pages/About.js';
import Contact from './pages/Contact.js';

const tagManagerArgs = { gtmId: 'GTM-TQJ2ZJ6J' };
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <>
    <Router>
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />}/>
            <Route path="/" element={<Home />} /> {/* Default Route */}
            <Route path="*" element={<NotFoundPage />} /> {/* 404 */}
          </Routes>
        </Router>
    </>
  );
}

export default App;
